<template>
  <div>
    <feather-icon
      v-b-tooltip.hover.righttop.v-primary
      title="Editar parcela"
      icon="EditIcon"
      size="15"
      @click="openEdit"
      style="cursor: pointer"
    />

    <b-modal title="Editar parcela" centered no-close-on-backdrop hide-footer hide-header-close v-model="editModal">
      <b-row class="mt-1 justify-content-center d-flex text-center">
        <p>Editando Parcela</p>
      </b-row>
      <b-row>
        <b-col class="p-2">
          <b-form-group label="Valor da parcela">
            <b-form-input class="mb-1" v-model="itemEdit.value" v-money="moneyMask" placeholder="Insira o valor">
              Valor
            </b-form-input>
          </b-form-group>

          <b-form-group label="Data da transação">
            <b-form-datepicker v-model="itemEdit.transaction_date" placeholder="dd/mm/aaaa"> </b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="justify-content-between d-flex mt-3 p-1">
        <b-button variant="warning" @click="closeEdit"> Cancelar </b-button>
        <b-button variant="primary" @click="installmentUpdate"> Confirmar </b-button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BButton, BCol, BFormInput, BFormGroup, BFormDatepicker } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BFormDatepicker
  },
  props: {
    item: { type: Object, default: () => {} },
    getItem: { type: Function, required: true }
  },
  data: () => ({
    editModal: false,
    itemEdit: [],
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false
    }
  }),

  mounted() {
    this.itemEdit = JSON.parse(JSON.stringify(this.item));
  },

  methods: {
    openEdit() {
      this.editModal = true;
      console.log(this.itemEdit);
    },
    closeEdit() {
      this.editModal = false;
    },
    async installmentUpdate() {
      try {
        await this.$store.dispatch('updateInstallment', {
          ...this.itemEdit,
          value: this.monetaryToNumber(this.itemEdit.value),
          revenue_id: this.$route.params.id
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Parcela alterada com sucesso.',
            icon: 'SuccessIcon',
            variant: 'success'
          }
        });

        console.log('Chamando getItem após atualização...');
        await this.getItem();
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao alterar parcela.',
            icon: 'XIcon',
            variant: 'danger'
          }
        });
      } finally {
        this.closeEdit();
      }
    }
  }
};
</script>
